body {
  margin: 0;
  padding: 0;
}

.login-form {
  width: 35rem;
  max-width: 90%;
  margin: 3rem auto;
}
  
.login-form-controls {
  width: 30rem;
  max-width: 90%;
  margin: 3rem auto;
  padding: 2rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.email_input, .password_input {
  width: 70%;
  height: 25px;
  border: 1px solid darkgray;
}

.form_control {
  margin: 10px;
}
  
.email_label {
  padding-right: 50px;
  font-family: 'cursive';
  font-size: 20px;
}

.password_label {
  padding-right: 20px;
  font-family: 'cursive';
  font-size: 20px;
}

.form_actions {
  padding-left: 20px;
  text-align: center;
}

.login_button {
  font-family: 'cursive';
  font-size: 20px;
}