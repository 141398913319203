.new_expense {
    width: 35rem;
    max-width: 90%;
  }
  
  .new-expense-form {
    width: 30rem;
    max-width: 90%;
    margin: 3rem auto;
    padding: 2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .form_controls_container {
    display: flex;
  }
  
  .header_title {
    position: sticky;
    top: 0;
    background-color: whitesmoke;
    font-size: 50px;
  }

  .background_img {
    height: 400px;
    width: 100%;
  }

  .web_elements_container {
    font-size: 32px;
    text-align: center;
    font-family: monospace;
    text-decoration: underline;
    margin: 10px;
  }