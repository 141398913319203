.profile_img {
    border-radius: 50%;
    position: absolute;
    left: 48px;
    top: 50px;
    border: 3px solid white;
    height: 88px;
}

.service_img {
    max-width: 600px;
    max-height: 300px;
    display: block;
    width: auto;
    height: auto;
}

.course_images {
    position: relative;
}

.course_container {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 3px 3px 3px 3px darkgray;
    margin: 50px;
}

.course_details {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.title {
    font-size: 30px;
    font-weight: bold;
    font-family: inherit;
    margin-bottom: 20px;
}

.title:hover {
    cursor: pointer;
    color: darkslategray;
}

.description {
    font-size: 18px;
    font-family: inherit;
    color: gray;
}

.service_img {
    width: 500px;
    height: 100%;
}