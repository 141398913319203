@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
}
.about_me {
  margin: 150px 10px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid darkgray;
}

.company_details {
  display: flex;
  justify-content: center;
  margin: 50px 20px;
  color: gray;
}

.footer {
  text-align: center;
}

.contact_info {
  text-align: left;
  padding-left: 50px;
}

.about_me_text {
  font-family: Arial;
  font-size: 20px;
}

.about_me_text {
  font-family: 'cursive' ;
  font-weight: bold;
  font-size: 30px;
}

.profile-info {
  font-family: 'cursive' ;
  font-size: 20px;
}

.profile_pic {
  border-radius: 50%;
}

.profile_wrapper {
  display: flex;
}

.read_more {
  display: block;
  font-family: 'cursive' ;
  font-style: italic;
  margin-top: 10px;
}

.name_wrapper {
  text-align: right;
  padding-right: 50px;
  font-family: Arial;
  font-size: 20px;
  border-right: 1px solid black;
  color: black;
}

.instagram, .youtube, .linkedIn {
  padding-right: 5px;
  color: blue;
}

.contact_text {
  padding-right: 10px;
  font-weight: bold;
  color: black;
}

.tag_line {
  font-size: 16px;
  color: darkgray;
}

.creative_minds {
  font-size: 20px;
}
.profile_img {
    border-radius: 50%;
    position: absolute;
    left: 48px;
    top: 50px;
    border: 3px solid white;
    height: 88px;
}

.service_img {
    max-width: 600px;
    max-height: 300px;
    display: block;
    width: auto;
    height: auto;
}

.course_images {
    position: relative;
}

.course_container {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 3px 3px 3px 3px darkgray;
    margin: 50px;
}

.course_details {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.title {
    font-size: 30px;
    font-weight: bold;
    font-family: inherit;
    margin-bottom: 20px;
}

.title:hover {
    cursor: pointer;
    color: darkslategray;
}

.description {
    font-size: 18px;
    font-family: inherit;
    color: gray;
}

.service_img {
    width: 500px;
    height: 100%;
}
body {
  margin: 0;
  padding: 0;
}

.login-form {
  width: 35rem;
  max-width: 90%;
  margin: 3rem auto;
}
  
.login-form-controls {
  width: 30rem;
  max-width: 90%;
  margin: 3rem auto;
  padding: 2rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.email_input, .password_input {
  width: 70%;
  height: 25px;
  border: 1px solid darkgray;
}

.form_control {
  margin: 10px;
}
  
.email_label {
  padding-right: 50px;
  font-family: 'cursive';
  font-size: 20px;
}

.password_label {
  padding-right: 20px;
  font-family: 'cursive';
  font-size: 20px;
}

.form_actions {
  padding-left: 20px;
  text-align: center;
}

.login_button {
  font-family: 'cursive';
  font-size: 20px;
}
.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 1px solid #2b3636;
    color: white;
    background: #2b3636;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: grey;
    border-color: grey;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
  }

button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
  
.radio_button_wrapper {
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 33.33%;
}

.radio_button_text {
    padding: 5px;
    margin-bottom: 10px;
    background-color: #2b3636;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
}
.checkboxes_wrapper {
  margin: 20px 10px;
  width: 33.33%;
}

.checkboxes_text {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #2b3636;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
}
.selectbox_wrapper {
  margin: 10px;
  width: 33.33%;
}

.selectorbox_text {
    padding: 5px;
    margin-bottom: 10px;
    background-color: #2b3636;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
}
.practice_page {
    margin: 0 50px;
    width: 100%;
}

.text_wrapper, .mouse_hover_text, .iframe_text {
    padding: 5px;
    margin-bottom: 10px;
    background-color: #2b3636;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
}

.input_text_wrapper, .input_number_wrapper , .date_picker_wrapper , .hyper_link_wrapper, .mouse_hover_wrapper {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 33.33%;
}

.iframe_wrapper {
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    border: 1px solid darkgray;
}

.iframe {
    border-width: 0;
    width: 100%;
    height: 500px;
}

.submit_button, .new_tab, .new_window, .alert_dialog, .confirm_dialog {
  margin: 10px;
  width: 33.33%;
  display: flex;
}

.grid_wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid darkgray;
    width: 100%;
    padding: 50px;
}

.hyper_link_wrapper {
    margin: 10px 20px;
}

.button:hover + .mouse_hover_content {
  display: flex;
}

.mouse_hover_content {
    display: none;
    flex-direction: column;
    padding: 10px;
    background-color: #f9f9f9;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}
table {
  padding: 10px;
}

th, td {
  padding: 5px;
}

table, th, td {
  border: 1px solid;
}

th {
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #2b3636;
  color: white;
}

#table-wrapper {
  position:relative;
}

#table-scroll {
  height: 548px;
  overflow-y :auto;
}

#table-wrapper table {
  width: 100%;
}

#table-wrapper table {
  color: black;
}

#table-wrapper th {
  color: white;
}

#table-wrapper table thead th .text {
  position:absolute;   
  top:-20px;
  z-index:2;
  height:20px;
  border:1px solid red;
}

.default_table {
  height: 100%;
  margin-right: 20px;
}
 
.tables_wrapper {
  display: flex;
  margin: 50px;
}

.web_table_text {
  padding: 5px;
  margin-bottom: 10px;
  background-color: #2b3636;
  border-radius: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
}
.new_expense {
    width: 35rem;
    max-width: 90%;
  }
  
  .new-expense-form {
    width: 30rem;
    max-width: 90%;
    margin: 3rem auto;
    padding: 2rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .form_controls_container {
    display: flex;
  }
  
  .header_title {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: whitesmoke;
    font-size: 50px;
  }

  .background_img {
    height: 400px;
    width: 100%;
  }

  .web_elements_container {
    font-size: 32px;
    text-align: center;
    font-family: monospace;
    text-decoration: underline;
    margin: 10px;
  }
