.practice_page {
    margin: 0 50px;
    width: 100%;
}

.text_wrapper, .mouse_hover_text, .iframe_text {
    padding: 5px;
    margin-bottom: 10px;
    background-color: #2b3636;
    border-radius: 10px;
    width: fit-content;
    color: white;
}

.input_text_wrapper, .input_number_wrapper , .date_picker_wrapper , .hyper_link_wrapper, .mouse_hover_wrapper {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 33.33%;
}

.iframe_wrapper {
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    border: 1px solid darkgray;
}

.iframe {
    border-width: 0;
    width: 100%;
    height: 500px;
}

.submit_button, .new_tab, .new_window, .alert_dialog, .confirm_dialog {
  margin: 10px;
  width: 33.33%;
  display: flex;
}

.grid_wrapper {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid darkgray;
    width: 100%;
    padding: 50px;
}

.hyper_link_wrapper {
    margin: 10px 20px;
}

.button:hover + .mouse_hover_content {
  display: flex;
}

.mouse_hover_content {
    display: none;
    flex-direction: column;
    padding: 10px;
    background-color: #f9f9f9;
    width: fit-content;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
}