.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 1px solid #2b3636;
    color: white;
    background: #2b3636;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    cursor: pointer;
    width: fit-content;
  }
  
  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: grey;
    border-color: grey;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.26);
  }

button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
  