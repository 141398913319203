.checkboxes_wrapper {
  margin: 20px 10px;
  width: 33.33%;
}

.checkboxes_text {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #2b3636;
    border-radius: 10px;
    width: fit-content;
    color: white;
}